// REMIX HMR BEGIN
if (!window.$RefreshReg$ || !window.$RefreshSig$ || !window.$RefreshRuntime$) {
  console.warn('remix:hmr: React Fast Refresh only works when the Remix compiler is running in development mode.');
} else {
  var prevRefreshReg = window.$RefreshReg$;
  var prevRefreshSig = window.$RefreshSig$;
  window.$RefreshReg$ = (type, id) => {
    window.$RefreshRuntime$.register(type, "\"app/root.tsx\"" + id);
  }
  window.$RefreshSig$ = window.$RefreshRuntime$.createSignatureFunctionForTransform;
}
var _s = $RefreshSig$();
import * as __hmr__ from "remix:hmr";
if (import.meta) {
  import.meta.hot = __hmr__.createHotContext(
  //@ts-expect-error
  "app/root.tsx");
}
// REMIX HMR END

import stylesheet from "~/tailwind.css";
import { json } from "@remix-run/node";
import { Form, Link, Links, LiveReload, Meta, Outlet, Scripts, ScrollRestoration, useLoaderData } from "@remix-run/react";
import { getUser } from './server/auth.server';
export const meta = () => {
  return [{
    title: "XMAS"
  }];
};
export const links = () => [{
  rel: "stylesheet",
  href: stylesheet
}];
export const loader = async ({
  request
}) => {
  const user = await getUser(request);
  return json({
    user
  });
};
export default function App() {
  _s();
  const {
    user
  } = useLoaderData();
  function UserButton({
    name
  }) {
    return <div className="flex justify-end flex-1 px-2">
        <div className="flex items-stretch">
          <details className="dropdown dropdown-end">
            <summary className="btn btn-ghost rounded-btn">
              {name}
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
              </svg>
            </summary>
            <ul className="menu dropdown-content z-[1] bg-neutral text-neutral-content p-2 shadow w-52">
              <li>
                <button>
                  <Link to={"/password"}>Zmień hasło</Link>
                </button>
              </li>
              <li>
                <Form action="/logout" method="post">
                  <button type="submit">Wyloguj</button>
                </Form>
              </li>
            </ul>
          </details>
        </div>
      </div>;
  }
  function LoginButton() {
    return <button className="btn btn-primary">
        <Link to={"/login"}>Logowanie</Link>
      </button>;
  }
  function AdminLinks() {
    return <div className="navbar-center">
        <Link to="/draw" className="btn btn-ghost">Losowanie</Link>
      </div>;
  }
  return <html lang="en" data-theme="christmas">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
      </head>
      <body>
        <div className="navbar">
          <div className="navbar-start">
            <Link to="/" className="btn btn-primary">Główna</Link>
          </div>
          {user?.role === "ADMIN" && <AdminLinks />}
          <div className="navbar-end">
            {user ? <UserButton name={user.name} /> : <LoginButton />}
          </div>
        </div>

        <div className="container mx-auto m-8">
          <Outlet />
          <div className="flex justify-between mt-8">
            <div className="w-1/5">
              <img alt="santa" src="/santa.png" class="-scale-x-100" />
            </div>
            <div className="w-1/5">
              <img alt="santa" src="/santa.png" />
            </div>
          </div>
        </div>

        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>;
}
_s(App, "FpjQZylbefWQChk+MjGNfSb2jDo=", false, function () {
  return [useLoaderData];
});
_c = App;
var _c;
$RefreshReg$(_c, "App");

window.$RefreshReg$ = prevRefreshReg;
window.$RefreshSig$ = prevRefreshSig;